$(function(){
// @media screen and (min-width: 768px) と同じ
var mql = window.matchMedia('screen and (min-width: 768px)');    
var printCheck = $('.js_print').css('display') == 'block' //印刷状態をチェック



//::::::::::::::::::::::::::::::::    
// スマホとタブレットでviewportを切替え!
//::::::::::::::::::::::::::::::::

var ua = navigator.userAgent;
var getDevice = (function(){    
    if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
        $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
        $('.txt-tellink,.txt_tellink').each(function () {
            var str = $(this).text();
            $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
        });
    } else {
        $('head').prepend('<meta name="viewport" content="width=1240">');
    var head = $('head');
    var headChildren = head.children();
    var childrenLength = headChildren.length;
    for(var i = 0;i < childrenLength;i++){
        var metaName = headChildren.eq(i).attr('name');
        if(metaName === 'viewport'){
            headChildren.eq(i).attr('content','width=1240');
        }
      }
    }
})()

//:::::::::::::::::::::::::::
// これでiPadが判定できる！！
//:::::::::::::::::::::::::::
var isIPad = /iPad|Macintosh/i.test(navigator.userAgent)
&& 'ontouchend' in document
if(isIPad){
  //console.info('iPadからのアクセスです')
$('footer').addClass('ipad');
//return 'tab';    
} else {
  //console.info('iPadからのアクセスじゃないです')
}

//:::::::::::::::::::::::::::::::
// scrollTop html/bodyの判定
//:::::::::::::::::::::::::::::::
var scrollableElement;
// document.scrollingElementに対応していればそれを使う
if ('scrollingElement' in document) {
  scrollableElement = document.scrollingElement;
}
// IEのとき
else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
  scrollableElement = document.documentElement;
}
// それ以外
else {
  scrollableElement = document.body;
}    

//::::::::::::::::::::
// スマホメニューの開閉 
//::::::::::::::::::::   

var menu = $('.menu');
var menuBtn = $('.menu-btn');
var spmenu = $('.spmenu');
var body = $('body');
var link = $('a');
var state = false;
var scrollpos;
var top_btn = $('.top-btn');    
//var headerheight = $("header").outerHeight(true);

//SPmenuを開閉する関数
function SPmenuClose( btn ) {
    if( btn === undefined ){
       btn = '';
       }
    //$('.trial-modal').hide();
    if( btn ){
        if ( $(btn).hasClass('open') ){
            menuBtn.removeClass('open');
            spmenu.removeClass('menuopen');
             //console.log("OPEN!");
            //$('.owl-carousel').trigger('play.owl.autoplay');
            
                if ($(this).scrollTop() > 200) {
            top_btn.addClass('fade-btn');}
        } else {
            menuBtn.addClass('open');
            spmenu.addClass('menuopen');
            top_btn.removeClass('fade-btn');
            //$('.owl-carousel').trigger('stop.owl.autoplay');
            
        }
    } else {
        menuBtn.removeClass('open');
        spmenu.removeClass('menuopen');
    }
}

//ページ内リンクのときはSPメニューを閉じる
var SPmenuLink =  $('.spmenu a[href*="#"]'); 
$( menuBtn.add(SPmenuLink) ).on('click',function () {
    SPmenuClose( this );
})
    
//:::::::::::::::::  
//サブメニューの開閉
//:::::::::::::::::   

//イベント伝播の判定 
if (!mql.matches) {    
var preventEvent = true;
    $(".submenu a").on("click", function () {
        preventEvent = false;
    })  
    
    $(".js_submenu").on("click", function () {
                if(preventEvent){
            event.preventDefault();}
        
        $(this).find(".submenu").slideToggle(300, 'easeInOutSine');
        //$(this).toggleClass('icon-role');
        $(this).toggleClass('icon-more')
        $(this).toggleClass('icon-minus');
    })
  
$(".js_submenu>a").on("click", function () {
preventEvent = true;    
 })  
}else {}
//::::::::::::::::::::    
// スライドtoggle
//::::::::::::::::::::    

$(".js_st").on("click", function () {
    $(this).next().slideToggle(300, 'easeInOutQuad');
})
   

//::::::::::::::::::::::::::::::::::::    
//TOPにもどるボタン
//::::::::::::::::::::::::::::::::::::  

$(window).scroll(function () {
    if ($(this).scrollTop() > 130) {
        top_btn.addClass('fade-btn');
        $('.float-bnr').addClass('fade-btn');
        return false;
    } else {
        top_btn.removeClass('fade-btn');
        $('.float-bnr').removeClass('fade-btn');
        return false;
    }
})

//:::::::::::::::::::::::::::::    
// TOPにもどるボタンのスムーズな動き
//:::::::::::::::::::::::::::::    
var top_btnA = $('.top-btn');
top_btnA.on('click', function () {
    var speed = 500; // ミリ秒
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
if ($(target).length) {    
    var position = target.offset().top;}
    $(scrollableElement).animate({
        scrollTop: position
    }, speed, 'easeInOutQuad');
    return false;
})

//headerの高さ    
var height = 75;
if (mql.matches) {
    var height = 100;    
} 
    
//////WPのログインバー
var WPadminbar = $("#wpadminbar");
if (WPadminbar.length) {
//$('.menu-btn,.submenu,.l-spmenu').addClass('js_');    
 
if (mql.matches){
 
}else{
WPadminbar.css({'position':'fixed','top':'0'});
$('.menu-btn').css('top','56px');
$('.l-spmenu').css('top','46px');
}
}else{} 
    
//:::::::::::::::::::::::::::::    
// TOPに戻るボタンをfooterに固定
//:::::::::::::::::::::::::::::
   
$(window).scroll(function () {
//if (!mql.matches) {var footHeight = 25;}
//else{var footHeight = 64;}    
    var pageHeight = $(document).height(); 
    var scrollPosition = $(window).height() + $(window).scrollTop(); 
    var footHeight = $("footer").innerHeight();
    
    if (pageHeight - scrollPosition <= footHeight) {        
        $(".top-btn").addClass('btn-fix');
    } 
else {
$(".top-btn").removeClass('btn-fix');
    }    
})

    
     
//objectFitImages();

//::::::::::::::::::::::::::::
//  外部ページから#で始まるリンクに
//  飛んできたときの設定
//  (headerの下に潜らないように) 
//::::::::::::::::::::::::::::    
$(function () {
    var url = $(location).attr('href');
    if (url.indexOf("#") != -1) {
        var anchor = url.split("#");
        var target = $('#' + anchor[anchor.length - 1]);
        if (target.length) {
            var position = target.offset().top - height;
            $(scrollableElement).scrollTop(position);
            //return false;
        }
        //console.log(position,target,anchor)
    }
})
    
    
//::::::::::::::::::::::::::::    
//  ページ内リンクが
//  headerの下に潜らないように
//::::::::::::::::::::::::::::     

$('a[href^="#"]').click(function () {
    var anchor = $(this).attr('href'); //hrefを取得
    var anchor = anchor.split("#"); //#で分割
    var target = "#" + anchor[1]; //#の値を取得
    var offset = $(target).offset().top; //飛び先の位置を取得
    //var startPosition = $('.page-back').offset().top; //飛び先の位置を取得
    var position = offset - height;
    //console.log(startPosition);
    //console.log(offset);
    $(scrollableElement).animate({
        scrollTop: position
    }, 500, 'easeInOutQuad');
    //return false;
})

    
//::::::::::::::::::::::::::::::::::::
//  フォーム確認画面時のラジオボタン
//::::::::::::::::::::::::::::::::::::

////確認ボタンを押した際の処理
//$('.wpcf7c-btn-confirm').on('click',function(){
//    var target = $('input[name="category"]:checked').val();
//    $('input[name="category"]').each(function(i){
//        if($(this).val() == target){
//            $(this).next('.wpcf7-list-item-label').addClass('categorylabel');
//        }
//    });
//});
//
////戻るボタンを押した際の処理
//$('.wpcf7c-btn-back').on('click',function(){
//    $('input[name="category"]').each(function(i){
//            $(this).next('.wpcf7-list-item-label').removeClass('categorylabel');
//    });
//})    

    
//news 画像にリンクを貼ったときはblankアイコン非表示
$(function(){
    //var imglink = $('#newsmain-txt');
  $("#article-free-area a:has(img)").addClass("remove-icon");
}) 

    
  
    
 
function checkBreakPoint(mql) {
if (mql.matches) {
//console.log('pcです');

//:::::::::::::::::  
//サブメニューの開閉
//:::::::::::::::::
var SubMenuOpen;
var is_Active = 1;
//console.log(is_Active);
    

     
$(".js_submenu").mouseenter(function () {  
    var that = $(this);
if(is_Active > 0){  
    //console.log('アクティブなのでメニューオープン'); 
    SubMenuOpen = setTimeout(function () {
        that.find(".submenu").addClass('submenu-open');
        is_Active = 1;
        //return false;
    }, 150);}else{}
}).mouseleave(function () {
   clearTimeout(SubMenuOpen);    
})
$(".js_submenu,main-menu-list").mouseleave(function () {
    $(".submenu").removeClass('submenu-open');    
    is_Active = 1;
    //console.log(is_Active);
//    console.log('アクティブになった'); 
//    return false;
})
   
$("header,main").mouseover(function () {
//is_Active = 1;
//console.log('アクティブになったよ');     
//console.log(is_Active);    
})

 $(".main-menu-list a").on("click", function (e) {
     e.stopPropagation();
})
    
//:::::::::::::::::  
//  リンクを無効に
//:::::::::::::::::
 $(".js_nolinksp").on("click", function () {
    event.preventDefault();
    //return false;
})
    
//:::::::::::::::::  
//  header固定
//:::::::::::::::::
//$(window).scroll(function () {
//    if ($(this).scrollTop() > 102) {
//        $('header').addClass('fix-header');
//        return false;
//    } else {
//        $('header').removeClass('fix-header');
//        return false;
//    }
//})    
    
  
} //(mql.matches)else if end

}//(mqlここまで)

// ブレイクポイントの瞬間に発火
mql.addListener(checkBreakPoint);


//:::::::::::::::::::::::::::       
//  スクロールアニメーション
//:::::::::::::::::::::::::::


checkBreakPoint(mql); // 初回チェック  
}); //$ふぁんくしょんおわり！